import { useTranslation } from "react-i18next";

import './SecondSection.scss'


import phone from "./images/phone.png";
import calls from "./images/calls.png";
import cloud from "./images/cloud.png";
import crm from "./images/crm.png";
import numbers from "./images/numbers.png";

function SecondSection() {
	const { t } = useTranslation();

	return (
		<section className="second-section">
			<div className="container">
				<div className="second-section__content">
					<div className="second-section__content-header">
						<h2>{t("secondsectioncontentheader")}</h2>
					</div>
					<div className="second-section__content-services">
						<div className="second-section__content-services__one">
							<div className="second-section__content-services__one-item">
								<div className="second-section__content-services__one-item__img">
									<img src={numbers} alt="Virtual Numbers" />
								</div>
                                <div className="second-section__content-services__one-item__text">
                                    <h3>
                                    {t("secondsectioncontentservicesoneitemtexth3")}
                                    </h3>
                                    <p>
                                    {t("secondsectioncontentservicesoneitemtextp")}
                                    </p>
                                </div>
							</div>
							<div className="second-section__content-services__one-item">
								<div className="second-section__content-services__one-item__img">
									<img src={crm} alt="CRM" />
                                    <div className="second-section__content-services__one-item__text">
                                    <h3>
                                    {t("secondsectioncontentservicesoneitemtexth32")}
                                    </h3>
                                    <p>
                                    {t("secondsectioncontentservicesoneitemtextp2")}
                                    </p>
                                </div>
								</div>
							</div>
						</div>
						<div className="second-section__content-services__two">
							<div className="second-section__content-services__two-item">
								<img src={phone} alt="Phone" />
							</div>
						</div>
						<div className="second-section__content-services__three">
							<div className="second-section__content-services__three-item">
								<div className="second-section__content-services__three-item__img">
									<img src={calls} alt="Calls" />
								</div>
                                <div className="second-section__content-services__three-item__text">
                                    <h3>
                                    {t("secondsectioncontentservicesthreeitemtexth3")}
                                    </h3>
                                    <p>
                                    {t("secondsectioncontentservicesthreeitemtextp")}
                                    </p>
                                </div>
							</div>
							<div className="second-section__content-services__three-item">
								<div className="second-section__content-services__three-item__img">
									<img src={cloud} alt="Cloud" />
								</div>
                                <div className="second-section__content-services__three-item__text">
                                    <h3>
                                    {t("secondsectioncontentservicesthreeitemtexth32")}
                                    </h3>
                                    <p>
                                    {t("secondsectioncontentservicesthreeitemtextp2")}
                                    </p>
                                </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default SecondSection;
