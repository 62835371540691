import "./Footer.scss";

import logo from "./images/logo2.png";

function Footer() {
	return (
		<footer>
			<div className="container">
				<div className="footer__content">
					<img src={logo} alt="MOM Voip" />
					<a href="https://t.me/MomVoIP_client_bot">
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17.2585 3.04551C17.2585 3.04551 18.8772 2.41426 18.7418 3.94717C18.6972 4.57842 18.2926 6.788 17.9776 9.17759L16.8985 16.2568C16.8985 16.2568 16.8085 17.2938 15.9989 17.4743C15.1897 17.6543 13.9755 16.843 13.7505 16.6626C13.5705 16.5272 10.378 14.498 9.25387 13.5063C8.93887 13.2355 8.57887 12.6947 9.29887 12.0634L14.0205 7.55425C14.5601 7.01259 15.0997 5.75009 12.8514 7.28342L6.55554 11.5668C6.55554 11.5668 5.83596 12.018 4.48721 11.6122L1.56387 10.7101C1.56387 10.7101 0.484706 10.0338 2.32846 9.35759C6.82554 7.23842 12.3568 5.07426 17.258 3.04509"
								fill="white"
							/>
						</svg>
						telegram
					</a>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
