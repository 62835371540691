import "./FirstSection.scss";
import ButtonComponent from "../Button/Button";
import { useTranslation } from "react-i18next";

function FirstSection() {
	const { t } = useTranslation();

	return (
		<section className="first-section">
			<div className="container">
				<div className="first-section__content">
					<div className="first-section__content-header">
						<h1>{t("h1")}</h1>
						<div className="first-section__content-header__sub">
							<h2>{t("firstsectioncontentheadersubh2")}</h2>
							<p>{t("firstsectioncontentheadersubp")}</p>
						</div>
					</div>
					<div className="first-section__content-btn">
						<ButtonComponent url="#form" text={t("firstsectioncontentheaderbtn")} />
					</div>
				</div>
			</div>
		</section>
	);
}

export default FirstSection;
