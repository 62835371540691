import React, { useState } from 'react';
import { Transition } from 'react-transition-group';
import i18n from '../../i18n'; // Путь к вашему i18n.js

import './LanguageSwitcher.scss'

function LanguageSwitcher() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
    setIsOpen(false); // Закрываем меню после выбора языка
  };

  return (
    <div className="language-switcher">
      <div className="language-switcher-btn" onClick={() => setIsOpen(!isOpen)}>
        {currentLanguage === 'en' ? 'EN' : 'RU'}
        <span className={`arrow ${isOpen ? 'up' : 'down'}`} />
      </div>
      <Transition in={isOpen} timeout={200} unmountOnExit>
        {(state) => (
          <div className={`language-switcher-menu ${state}`}>
            <button onClick={() => changeLanguage('en')}>EN</button>
            <button onClick={() => changeLanguage('ru')}>RU</button>
          </div>
        )}
      </Transition>
    </div>
  );
}

export default LanguageSwitcher;
