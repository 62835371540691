import { useTranslation } from "react-i18next";


import './ThirdSection.scss';
import flexibility from './images/flexibility.png'
import reliability from './images/reliability.png'
import savings from './images/savings.png'

function ThirdSection() {
    const { t } = useTranslation();



    return ( 
        <section className="third-section">
            <div className="container">
                <div className="third-section__content">
                    <div className="third-section__content-items">
                        <div className="third-section__content-items__title">
                            <h2>
                            {t("thirdsectioncontentitemstitle")}
                            </h2>
                        </div>
                        <div className="third-section__content-items__vars">
                            <div className="third-section__content-items__vars-item">
                                <div className="third-section__content-items__vars-item__img">
                                    <img src={reliability} alt="Reliability" />
                                </div>
                                <div className="third-section__content-items__vars-item__text">
                                    <h4>
                                    {t("thirdsectioncontentitemsvarsitemtexth41")}
                                    </h4>
                                    <p>
                                    {t("thirdsectioncontentitemsvarsitemtextp1")}
                                    </p>
                                </div>
                            </div>
                            <div className="third-section__content-items__vars-item">
                                <div className="third-section__content-items__vars-item__img">
                                    <img src={flexibility} alt="Flexibility" />
                                </div>
                                <div className="third-section__content-items__vars-item__text">
                                    <h4>
                                    {t("thirdsectioncontentitemsvarsitemtexth42")}
                                    </h4>
                                    <p>
                                    {t("thirdsectioncontentitemsvarsitemtextp2")}
                                    </p>
                                </div>
                            </div>
                            <div className="third-section__content-items__vars-item">
                                <div className="third-section__content-items__vars-item__img">
                                    <img src={savings} alt="Savings" />
                                </div>
                                <div className="third-section__content-items__vars-item__text">
                                    <h4>
                                    {t("thirdsectioncontentitemsvarsitemtexth43")}
                                    </h4>
                                    <p>
                                    {t("thirdsectioncontentitemsvarsitemtextp3")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
     );
}

export default ThirdSection;