import SimpleSlider from '../Slider/SimpleSlider'

import { useTranslation } from "react-i18next";

import "./FourSection.scss";

function FourSection() {
	const { t } = useTranslation();

	return (
		<section className="four-section">
			<div className="container">
				<div className="four-section__content">
					<div className="four-section__content-header">
						<div className="four-section__content-header__text">
							<h2>
                            {t("foursectioncontentheadertext")}
                            </h2>
						</div>
						<div className="four-section__content-header__paragraph">
                            <p>
                            {t("foursectioncontentheaderparagraph")}
                                <span>
                                {t("foursectioncontentheaderparagraphcolor")}
                                </span>
                                {t("foursectioncontentheaderparagraphdot")}
                            </p>
							
						</div>
					</div>
                    <div className="four-section__content-slider">
                        <SimpleSlider />
                    </div>
				</div>
			</div>
		</section>
	);
}

export default FourSection;
