import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom"; // добавим useHistory
import "./TelegramForm.scss";
import Button from "../Button/Button";

import ok from './images/ok.png'

function TelegramForm() {
    const [name, setName] = useState("");
    const [telegram, setTelegram] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const { t } = useTranslation();
    const history = useHistory(); // получаем объект history

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('telegram', telegram);

            const response = await fetch("/sendToTelegram.php", {
                method: "POST",
                body: formData,
            });
            const responseData = await response.json();

            if (response.ok) {
                setSuccessMessage(responseData.message);
                setErrorMessage("");
                // После успешной отправки формы делаем редирект на /thanks
                history.push("/thanks");
            } else {
                setErrorMessage(responseData.error);
                setSuccessMessage("");
            }
        } catch (error) {
            console.error("Ошибка:", error);
            setErrorMessage("Ошибка при отправке данных в Telegram!");
            setSuccessMessage("");
        }
    };

    return (
        <form onSubmit={handleSubmit} id="form">
            <div>
                <input
                    placeholder={t("name")}
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div>
                <input
                    placeholder={t("telegram")}
                    type="text"
                    id="telegram"
                    value={telegram}
                    onChange={(e) => setTelegram(e.target.value)}
                />
            </div>
            {successMessage && (
                <div className="success">
                    <img src={ok} alt="Success" />
                    <h3>{successMessage}</h3>
                </div>
            )}
            {errorMessage && <div className="error">{errorMessage}</div>}
            <Button text={t("send")} type="submit" />
        </form>
    );
}

export default TelegramForm;
