import { useTranslation } from "react-i18next";

import TelegramForm from '../TelegramForm/TelegramForm'

import "./FiveSection.scss";
import bgIMG from './images/five-bg.png'

function FiveSection() {
	const { t } = useTranslation();

	return (
		<section className="five-section">
			<div className="container">
				<div className="five-section__content">
					<div className="five-section__content-form">
						<h2>{t("fivesectionheader")}</h2>
                        <TelegramForm />
					</div>
                    <div className="five-section__content-img">
                        <img src={bgIMG} alt={t("fivesectionheader")} />
                    </div>
				</div>
			</div>
		</section>
	);
}

export default FiveSection;
