import React, { useState } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Slider.scss'

import sliderbg1 from "./images/sliderbg-1.png";
import sliderbg2 from "./images/sliderbg-2.png";
import sliderbg3 from "./images/sliderbg-3.png";
import sliderbg4 from "./images/sliderbg-4.png";
import sliderbg5 from "./images/sliderbg-5.png";

import slideIcon1 from "./images/slide1-icon.png";
import slideIcon2 from "./images/slide2-icon.png";
import slideIcon3 from "./images/slide3-icon.png";
import slideIcon4 from "./images/slide4-icon.png";
import slideIcon5 from "./images/slide5-icon.png";


export default function SimpleSlider() {


  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);

  var settings = {
    dots: true,
    dotsClass: "slick-dots slick-dots-custom",
    customPaging: (i) => (
      <span className={i === currentSlide ? 'slick-active' : ''}>
        {`0${i + 1}`.slice(-2)} <span className="dark">- 05</span>
      </span>
    ),
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: setCurrentSlide
  };


  return (
    <Slider {...settings}>
      <div className="slider">
        <img src={sliderbg1} alt={t("slide1head")} />
        <div className="sliderContent">
          <img src={slideIcon1} alt={t("slide1head")} />
          <h3>{t("slide1head")}</h3>
          <p>
            {t("slide1desc")}
          </p>
        </div>
      </div>
      <div className="slider">
        <img src={sliderbg2} alt={t("slide2head")} />
        <div className="sliderContent">
          <img src={slideIcon2} alt={t("slide2head")} />
          <h3>{t("slide2head")}</h3>
          <p>
            {t("slide2desc")}
          </p>
        </div>
      </div>
      <div className="slider">
        <img src={sliderbg3} alt={t("slide3head")} />
        <div className="sliderContent">
          <img src={slideIcon3} alt={t("slide3head")} />
          <h3>{t("slide3head")}</h3>
          <p>
            {t("slide3desc")}
          </p>
        </div>
      </div>
      <div className="slider">
        <img src={sliderbg4} alt={t("slide4head")} />
        <div className="sliderContent">
          <img src={slideIcon4} alt={t("slide4head")} />
          <h3>{t("slide4head")}</h3>
          <p>
            {t("slide4desc")}
          </p>
        </div>
      </div>
      <div className="slider">
        <img src={sliderbg5} alt={t("slide5head")} />
        <div className="sliderContent">
          <img src={slideIcon5} alt={t("slide5head")} />
          <h3>{t("slide5head")}</h3>
          <p>
            {t("slide5desc")}
          </p>
        </div>
      </div>
    </Slider>
  );
}
