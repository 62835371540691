// ThanksPage.js
import React from "react";
import { useTranslation } from "react-i18next";

import "./ThanksPage.scss";
import ok from "../TelegramForm/images/ok.png";

const ThanksPage = () => {
	const { t } = useTranslation();
	return (
		<section className="thanks-section">
			<div className="container">
				<div className="thanks-section__content">
					<img src={ok} alt="Ok" />

					<h3>{t("setSuccessMessage")}</h3>
					<h4>{t("setSuccessMessage2")}</h4>
				</div>
			</div>
		</section>
	);
};

export default ThanksPage;
