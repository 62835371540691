import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  ru: {
    translation: {
      h1: 'Связь без границ',
      firstsectioncontentheadersubh2: 'Надежное решение для роста и оптимизации',
      firstsectioncontentheadersubp: 'Наши услуги IP-телефонии обеспечат надежность, гибкость и высокое качество обслуживания. С нами вы получите индивидуальный подход, экономию времени и ресурсов, а также уверенность в том, что ваша связь всегда будет работать без сбоев',
      firstsectioncontentheaderbtn: 'Связаться',
      secondsectioncontentheader: 'Наши услуги',
      secondsectioncontentservicesoneitemtexth3: 'Виртуальные номера',
      secondsectioncontentservicesoneitemtextp: 'Получите номер в любом городе мира и принимайте звонки через интернет',
      secondsectioncontentservicesoneitemtexth32: 'Интеграция с CRM',
      secondsectioncontentservicesoneitemtextp2: 'Для удобства управления клиентской базой.',
      secondsectioncontentservicesthreeitemtexth3: 'Международные звонки',
      secondsectioncontentservicesthreeitemtextp: 'Общайтесь с партнерами и клиентами без дополнительных затрат.',
      secondsectioncontentservicesthreeitemtexth32: 'Облачная телефонная станция',
      secondsectioncontentservicesthreeitemtextp2: 'Без дорогостоящего оборудования и его обслуживания',
      thirdsectioncontentitemstitle: 'Почему выбирают нас?',
      thirdsectioncontentitemsvarsitemtexth41: 'Надежность',
      thirdsectioncontentitemsvarsitemtextp1: 'обеспечиваем стабильное качество связи и минимальные простои.',
      thirdsectioncontentitemsvarsitemtexth42: 'Гибкость',
      thirdsectioncontentitemsvarsitemtextp2: 'наши решения адаптируются под любые потребности вашего бизнеса.',
      thirdsectioncontentitemsvarsitemtexth43: 'Экономия',
      thirdsectioncontentitemsvarsitemtextp3: 'Низкие расходы с высоким качеством обслуживания.',
      foursectioncontentheadertext: 'Наш подход',
      foursectioncontentheaderparagraph: 'Мы стремимся предоставить нашим клиентам не просто технические решения, а комплексный подход к коммуникационным ',
      foursectioncontentheaderparagraphcolor: 'потребностям вашего бизнеса',
      foursectioncontentheaderparagraphdot: '.',
      slide1head: 'Технологические инновации',
      slide1desc: 'Мы постоянно следим за последними технологическими тенденциями в области IP-телефонии и стремимся интегрировать их в наши услуги. Это позволяет нам предлагать клиентам передовые решения',
      slide2head: 'Индивидуальный подход',
      slide2desc: 'Наши эксперты в области IP-телефонии тщательно изучают особенности вашего бизнеса, его цели и задачи, чтобы разработать оптимальное решение, которое точно соответствует вашим потребностям.',
      slide3head: 'Гибкость и масштабируемость',
      slide3desc: 'потребности бизнеса могут меняться со временем, поэтому мы предлагаем различные планы и тарифы, которые можно легко адаптировать под изменяющиеся потребности вашего бизнеса.',
      slide4head: 'Надежность и качество ',
      slide4desc: 'Наша инфраструктура построена с учетом высокой надежности и стабильности, а наша команда профессионалов всегда готова оперативно реагировать на любые запросы и проблемы.',
      slide5head: 'Оптимизация затрат',
      slide5desc: 'Мы понимаем важность экономии ресурсов для бизнеса, поэтому наша цель - предложить клиентам эффективные решения, которые помогут сократить затраты без ущерба качеству обслуживания. ',
      fivesectionheader: 'Мы готовы ответить на все ваши вопросы',
      setSuccessMessage: 'Готово!',
      setSuccessMessage2: 'Наши менеджеры свяжуться с вами в ближайшее время',
      setErrorMessage: 'Ошибка!',
      name: 'Имя',
      telegram: '@Телеграмм',
      send: 'Отправить',
      modalHead: 'Для быстрой связи оставьте заявку прямо сейчас'
    },
  },
  en: {
    translation: {
      h1: 'Communication without borders',
      firstsectioncontentheadersubh2: 'A reliable solution for growth and optimization',
      firstsectioncontentheadersubp: 'Our IP telephony services guarantee reliability, flexibility, and high-quality service. With us, you will receive personalized attention, save time and resources, and have the confidence that your communication will always operate smoothly',
      firstsectioncontentheaderbtn: 'Contact us',
      secondsectioncontentheader: 'Our services',
      secondsectioncontentservicesoneitemtexth3: 'Virtual numbers',
      secondsectioncontentservicesoneitemtextp: 'Get a number in any city worldwide and receive calls over the internet.',
      secondsectioncontentservicesoneitemtexth32: 'Integration with CRM',
      secondsectioncontentservicesoneitemtextp2: 'For the convenience of managing the client database.',
      secondsectioncontentservicesthreeitemtexth3: 'International calls',
      secondsectioncontentservicesthreeitemtextp: 'Communicate with partners and clients without any additional costs.',
      secondsectioncontentservicesthreeitemtexth32: 'Cloud-based phone system',
      secondsectioncontentservicesthreeitemtextp2: 'Without expensive equipment and its maintenanc',
      thirdsectioncontentitemstitle: 'Why choose us?',
      thirdsectioncontentitemsvarsitemtexth41: 'Reliability',
      thirdsectioncontentitemsvarsitemtextp1: 'We provide stable call quality and minimal downtime.',
      thirdsectioncontentitemsvarsitemtexth42: 'Flexibility',
      thirdsectioncontentitemsvarsitemtextp2: 'Our solutions adapt to meet any business needs.',
      thirdsectioncontentitemsvarsitemtexth43: 'Savings',
      thirdsectioncontentitemsvarsitemtextp3: 'Low costs with high-quality service.',
      foursectioncontentheadertext: 'Our approach',
      foursectioncontentheaderparagraph: 'We aim to offer our clients not just technical solutions, but a comprehensive approach to the communication ',
      foursectioncontentheaderparagraphcolor: 'needs of your business',
      foursectioncontentheaderparagraphdot: '.',
      slide1head: 'Technological innovations',
      slide1desc: '"We continuously monitor the latest technological trends in the field of IP telephony and strive to integrate them into our services. This enables us to offer clients cutting-edge solutions.',
      slide2head: 'Individual approach',
      slide2desc: 'Our experts in IP telephony carefully study the specifics of your business, its goals, and objectives to develop an optimal solution that precisely meets your needs.',
      slide3head: 'Cost optimization',
      slide3desc: 'We understand the importance of resource saving for businesses, so our goal is to offer clients efficient solutions that help reduce costs without compromising service quality.',
      slide4head: 'Flexibility and scalability',
      slide4desc: 'Business needs may change over time, so we offer various plans and tariffs that can be easily adapted to accommodate the evolving needs of your business.',
      slide5head: 'Reliability and quality ',
      slide5desc: 'Our infrastructure is built with high reliability and stability in mind, and our team of professionals is always ready to promptly respond to any inquiries and issues.',
      fivesectionheader: 'We are ready to answer all your questions',
      setSuccessMessage: 'Ready!',
      setSuccessMessage2: 'Our managers will contact you shortly',
      setErrorMessage: 'Error!',
      name: 'Name',
      telegram: '@Telegram',
      send: 'Send',
      modalHead: 'For quick contact, leave your inquiry now.'



    },
  },
  // Другие языки...
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'ru', // Установка дефолтного языка
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie'],
    },
  });

export default i18n;
