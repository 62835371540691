import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header/Header";
import FirstSection from "./components/FirstSection/FirstSection";
import SecondSection from "./components/SecondSection/SecondSection";
import ThirdSection from "./components/ThirdSection/ThirdSection";
import FourSection from "./components/FourSection/FourSection";
import FiveSection from "./components/FiveSection/FiveSection";
import Footer from "./components/Footer/Footer";
import Modal from "./components/Modal/Modal";
import ThanksPage from "./components/ThanksPage/ThanksPage";

function App() {
	return (
		<Router>
			<div className="App">
				<Header />
				<Switch>
					<Route path="/" exact>
						<FirstSection />
						<SecondSection />
						<ThirdSection />
						<FourSection />
						<FiveSection />
						<Modal />
						<Footer />
					</Route>
					<Route path="/thanks">
						<ThanksPage />
					</Route>
				</Switch>
			</div>
		</Router>
	);
}

export default App;
