import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom"; // добавим useHistory

import "./Modal.scss";
import arrow from "../Slider/images/arrow-right.png";
import x from './images/x.png'
import ok from '../TelegramForm/images/ok.png'

function TelegramLoginModal({ isOpen, onClose }) {
    const [telegram2, setTelegram2] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const { t } = useTranslation();
    const history = useHistory(); // получаем объект history

    useEffect(() => {
        // Устанавливаем интервал для скрытия и отображения модального окна каждые 5 секунд
        const intervalId = setInterval(() => {
            const modal = document.querySelector(".modal");
            if (modal) {
                // Если модальное окно видимо, скрываем его
                if (modal.style.display === "none") {
                    modal.style.display = "block";
                }
            }
        }, 5000);

        // Возвращаем функцию для очистки интервала при размонтировании компонента
        return () => clearInterval(intervalId);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch("/sendToTelegram2.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ telegram2 }),
            });
            const responseData = await response.json();

            if (response.ok) {
                setErrorMessage("");
                setSuccessMessage("Готово! Наши менеджеры свяжутся с вами в ближайшее время");
                setTelegram2(""); // Очищаем поле ввода после успешной отправки
                // После успешной отправки формы делаем редирект на /thanks
                history.push("/thanks");
            } else {
                setErrorMessage(
                    responseData.error || "Ошибка при отправке логина в Telegram!"
                );
                setSuccessMessage("");
            }
        } catch (error) {
            console.error("Ошибка:", error);
            setErrorMessage("Ошибка при отправке логина в Telegram!");
            setSuccessMessage("");
        }
    };

    return (
        <div className={`modal ${isOpen ? "open" : ""}`}>
            <div className="modal-content">
                <span className="close" onClick={() => onClose()}>
                    <img src={x} alt="" />
                </span>

                {!successMessage && (
                    <form onSubmit={handleSubmit} className="formModal">
                        <h2>{t("modalHead")}</h2>
                        <div className="formModal__inner">
                            <input
                                placeholder={t("telegram")}
                                type="text"
                                id="telegram"
                                value={telegram2}
                                onChange={(e) => setTelegram2(e.target.value)}
                            />
                            <button type="submit" className="formModal__inner-Btn">
                                <img src={arrow} alt="" />
                            </button>
                        </div>
                        {errorMessage && <div className="error">{errorMessage}</div>}
                    </form>
                )}
                {successMessage && (
                    <div className="success">
                        <img src={ok} alt="" />
                        <b> {t('setSuccessMessage')}</b> {t('setSuccessMessage2')}
                    </div>
                )}
            </div>
        </div>
    );
}

export default TelegramLoginModal;
