import React, { useState, useEffect } from 'react';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import "./Header.scss";
import logo from "./images/logo.png";

function Header() {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={isScrolled ? "scrolled" : ""}>
            <div className="container">
                <div className="header__content">
                    <a href="/"><img src={logo} alt="MomVoip" /></a>
                    <LanguageSwitcher />
                </div>
            </div>
        </header>
    );
}

export default Header;
