import "./Button.scss";

function ButtonComponent({ url, text }) {
	return (
		<button className="regBtn">
			<a href={url}>{text}</a>
		</button>
	);
}

export default ButtonComponent;
